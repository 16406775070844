import { render, staticRenderFns } from "./MyIntroduce.vue?vue&type=template&id=02bfc8d8&scoped=true"
import script from "./MyIntroduce.vue?vue&type=script&lang=js"
export * from "./MyIntroduce.vue?vue&type=script&lang=js"
import style0 from "./MyIntroduce.vue?vue&type=style&index=0&id=02bfc8d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bfc8d8",
  null
  
)

export default component.exports